/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Row, Container } from "react-bootstrap"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            facebook
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <Container>
      <Row>
        <Col>
          <div className="bio text-center mt-3 mb-3">
            <StaticImage
              className="bio-avatar m-auto"
              layout="fixed"
              formats={["auto", "webp", "avif"]}
              src="../images/square-roundel-smaller.png"
              width={50}
              height={50}
              quality={95}
              alt="Profile picture"
            />
            {author?.name && (
              <p className="mt-3">
                Written by <strong>{author.name}</strong>{" "}
                {author?.summary || null}
                {` `}
                <a href={`https://facebook.com/${social?.facebook || ``}`}>
                  You should like them on Facebook
                </a>
                {" and "}
                <a href={`https://twitter.com/${social?.twitter || ``}`}>
                  follow them on Twitter
                </a>.
              </p>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Bio
